
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TargetListModal from '@/layout/header/partials/association/TargetListDrawer.vue';
import TargetChangeSummeryModal from '@/layout/header/partials/association/TargetChangeSummeryDrawer.vue';

import OrganizationModal from '@/components/modals/forms/settings/OrganizationModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: 'pending-entity-target',
  components: {
    TargetListModal,
    TargetChangeSummeryModal,
    Form,
    Datatable,
    Field,
    Multiselect,
  },
  data() {
    return {
      entity: {
        tranche_id: [] as any,
        entity_info_id: [] as any,
      } as any,
      targetList: [],
      association: [] as any,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Phase',
          key: 'tranche',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Association',
          key: 'association',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Target Year Id',
          key: 'year_id',
          sortable: true,
        },
        {
          name: 'Target Year From',
          key: 'targetYearFrom',
          sortable: true,
        },
        {
          name: 'Target Year To',
          key: 'targetYearTo',
          sortable: true,
        },
        {
          name: 'Trainee Number',
          key: 'traineeNumber',
          sortable: true,
        },
      ],
      load: false,
      tableData: [],
      loading: false,
      courseInfoData: false,
      showtargetList: false,

      lists: [],
      search: '',
      componentKey: 0,

      tranche: [] as any,
      optionsTranche: [] as any,

      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
    };
  },
  async created() {
    this.emitter.on('target-updated', async () => {
      await this.targetDataList();
    });
    await this.getTranche();
    await this.associationList();
  },
  methods: {
    filterEntity(entity) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.entity.entity_info_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.entity.entity_info_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
          this.tranche.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      await ApiService.get('entity/list')
        .then((response) => {
          this.association = response.data.data;
          this.tempAssociation = this.association;

          this.tempAssociation.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.tempId.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      //form submit
    },

    async targetDataList() {
      this.load = true;
      await ApiService.get(
        'entity/target/pending-list?tranche_id=' +
        this.entity.tranche_id +
        '&entity_info_id=' +
        this.associationData
      )
        .then((response) => {
          this.showtargetList = true;
          this.load = false;
          this.targetList = response.data.data;

          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/target/approve/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/target/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Reject!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deleted!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('entity/target/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('target-updated', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    edit(data) {
      this.emitter.emit('edit-modal-data', data);
    },
    view(data) {
      this.emitter.emit('view-modal-data', data);
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
